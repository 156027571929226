export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [2];

export const dictionary = {
		"/": [~4],
		"/404": [5],
		"/about": [~6],
		"/blog": [~7,[2]],
		"/blog/[id]": [8,[2]],
		"/case-studies": [9,[3]],
		"/case-studies/[id]": [10,[3]],
		"/consumer-health-data-privacy": [11],
		"/contact-us": [12],
		"/platform": [~13],
		"/privacy-choices": [14],
		"/privacy-policy": [15],
		"/sentry": [16],
		"/sign-in": [17],
		"/solutions": [~18],
		"/terms-and-conditions": [19]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';